import {Serializer} from '@matchsource/api-utils';
import {CoordinatorModel} from '@matchsource/models/coordinator';
import {CaseResourceDto} from '@matchsource/api-generated/common';

export const coordinatorSerializer: Serializer<CoordinatorModel, CaseResourceDto> = {
  fromDTO(input: CaseResourceDto): CoordinatorModel {
    return {
      id: input.id,
      firstName: input.firstName,
      lastName: input.lastName,
      fullNameAlt: `${input.firstName} ${input.lastName}`,
      fullName: `${input.lastName}, ${input.firstName}`,
    };
  },
};
